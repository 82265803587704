/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";

@import '~@angular/cdk/overlay-prebuilt.css';
@import './custom-theme.scss';

html, body { height: 100%; font-size: 80%;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Estilos propios del tema */
.zonaoculta {
  opacity: 0.3;
}

.main-header, .main-sidenav, 
.clear-sidenav, .disable_ripple, 
.menu-expansion-panel .mat-expansion-panel-body,
.logomenu,
table th
{
  background-color: $maincolor;
  color: $maintext;
}

.mat-expansion-panel-header.mat-expanded {
  background-color: $mainaccent !important;  
  color: $maintext !important;
}

.disable_ripple:hover,
.mat-list-item:hover {
  background-color: $mainaccent !important; 
  color: $accenttext !important;
}

.toolbar-title,
.mat-expansion-panel-header-title {
  color: $maintext;
}

::ng-deep .disable_ripple .mat-expansion-indicator::after {
  color: $maintext !important;
}

::ng-deep .menu-expansion-panel .mat-expansion-panel-body {
  background: $maincolor !important;
  color: $maintext !important;
  padding: 0 0px 10px !important;
}

.clear-sidenav,
.main-toolbar-icon,
.mat-list-base .mat-list-item, .mat-list-base .mat-list-option  {
  color: $maintext;
}
.disable_ripple .mat-expansion-indicator::after {
  color: $maintext !important;
}

.clear-sidenav:hover,
.main-toolbar-icon:hover {
  color: $accenttext;
}

.toolbar-bread {
  color:$maintext;
}

.breadcrumb-item a {
  color:$maintext;
}
.breadcrumb-item.active {
  color: $accenttext;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px $mainsoft;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $mainsoft;
  border-radius: 10px;
} 



/* Fin estilos del tema*/


.icon-actions-tables{
  vertical-align: top;
}

.overflow-auto{
  overflow: auto;

}
.mat-drawer-contentModify{
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow: none;
}
.login_body {    background-color: #373330;}
.altura100 {
    height: 100%;
    margin-left: 0 !important}
.anchura100 {
    width: 100%;
    margin-left: 0 !important;
}
.login-header {
  display: flex;
  justify-content: center;
}
.logo-img {
  max-width: 96%;
}
.logo-portada {
  max-width: 300px;
}
.alerta {
    font-size: large;
}
.alerta-login {
    display: inline-block;
}
/*Sobreescritura de botones boostrap*/
/*btn-magento-naranja*/
.btn-magento-naranja {background-color: RGB(0,32,96);}

.btn-magento-naranja{color:#fff;background-color:RGB(0,32,96);border-color:RGB(0,32,96);margin: 10px !important; }

/*.btn-magento-naranja:focus,.btn-magento-naranja.focus{box-shadow:0 0 0 1px #007bdb;}*/
.btn-magento-naranja.disabled,.btn-magento-naranja:disabled{color:#002c76;background-color:#fff;border-color:#002c76}
.btn-magento-naranja:not(:disabled):not(.disabled):active,.btn-magento-naranja:not(:disabled):not(.disabled).active,.show>.btn-magento-naranja.dropdown-toggle{color:#002c76;background-color:#fff;border-color:#002c76}
.btn-magento-naranja:not(:disabled):not(.disabled):active:focus,.btn-magento-naranja:not(:disabled):not(.disabled).active:focus,.show>.btn-magento-naranja.dropdown-toggle:focus{color:#fff;background-color:#002c76;border-color:#002c76}
.btn-magento-naranja:hover{color:#fff;background-color:#002c76;border-color:#002c76;}


/*.btn-outline-primary{color:teal;background-color:transparent;background-image:none;border-color:teal}.btn-outline-primary:hover{color:#222;background-color:#009a9a;border-color:teal}
.btn-outline-primary:focus,.btn-outline-primary.focus{box-shadow:0 0 0 .2rem rgba(0,128,128,0.5)}
.btn-outline-primary.disabled,.btn-outline-primary:disabled{color:teal;background-color:transparent}
.btn-outline-primary:not(:disabled):not(.disabled):active,.btn-outline-primary:not(:disabled):not(.disabled).active,.show>.btn-outline-primary.dropdown-toggle{color:#fff;background-color:#009a9a;border-color:teal}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,.btn-outline-primary:not(:disabled):not(.disabled).active:focus,.show>.btn-outline-primary.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(0,128,128,0.5)}*/

/*btn-magento-atras*/
.btn-magento-atras {background-color: transparent;}

.btn-magento-atras{
  color:#000;
  background-color:transparent;
  border-color:transparent;
  background-image: url(/assets/icon/atras.svg);
  padding: 20px 0 20px 36px;
  background-repeat: no-repeat;
  background-position: left calc(0.375em + -0.8125rem) center;
  background-size: calc(1.5em ) calc(1.5em);
}

.btn-magento-atras:hover{color:#000; background-color:transparent;border-color:transparent; }
.btn-magento-atras:focus,.btn-magento-atras.focus{box-shadow:0 0 0 1px #007cdb23;}
.btn-magento-atras.disabled,.btn-magento-atras:disabled{color:#000;background-color:rgba(233, 163, 126, 0.1);border-color:#005a5a13}
.btn-magento-atras:not(:disabled):not(.disabled):active,.btn-magento-atras:not(:disabled):not(.disabled).active,.show>.btn-magento-atras.dropdown-toggle{color:#000;background-color:rgba(233, 95, 21,0.1);border-color:#fff}
.btn-magento-atras:not(:disabled):not(.disabled):active:focus,.btn-magento-atras:not(:disabled):not(.disabled).active:focus,.show>.btn-magento-atras.dropdown-toggle:focus{box-shadow:0 0 0 1px rgba(0,90,90,0.1)}

/*btn-magento-gris*/
.btn-magento-gris {background-color: #514943;}

.btn-magento-gris{color:#FFF;background-color:#514943;border-color:transparent}

.btn-magento-gris:hover{color:#FFF; background-color:#35302c;border-color:#35302c;box-shadow: 0 0 0 1px #007cdbd8;}
.btn-magento-gris:focus,.btn-magento-gris.focus{box-shadow:0 0 0 1px #007cdb23;}
.btn-magento-gris.disabled,.btn-magento-gris:disabled{color:#FFF;background-color:#726f6d;border-color:#726f6d}
.btn-magento-gris:not(:disabled):not(.disabled):active,.btn-magento-gris:not(:disabled):not(.disabled).active,.show>.btn-magento-gris.dropdown-toggle{color:#FFF;background-color:#514943;border-color:#fff}
.btn-magento-gris:not(:disabled):not(.disabled):active:focus,.btn-magento-gris:not(:disabled):not(.disabled).active:focus,.show>.btn-magento-gris.dropdown-toggle:focus{box-shadow:0 0 0 1px rgba(0,90,90,0.7)}

/*btn-magento-gris-claro*/
.btn-magento-gris-claro {background-color: #E3E3E3;}

.btn-magento-gris-claro{color:#41362f;background-color:#E3E3E3;border-color:transparent}

.btn-magento-gris-claro:hover{color:#41362f; background-color:#E3E3E3;border-color:#E3E3E3;box-shadow: 0 0 0 1px rgba(173,173,173,0.7);}
.btn-magento-gris-claro:focus,.btn-magento-gris-claro.focus{box-shadow:0 0 0 1px #007cdb23;}
.btn-magento-gris-claro.disabled,.btn-magento-gris-claro:disabled{color:#E3E3E3;background-color:#726f6d;border-color:#726f6d}
.btn-magento-gris-claro:not(:disabled):not(.disabled):active,.btn-magento-gris-claro:not(:disabled):not(.disabled).active,.show>.btn-magento-gris-claro.dropdown-toggle{color:#FFF;background-color:#E3E3E3;border-color:#fff}
.btn-magento-gris-claro:not(:disabled):not(.disabled):active:focus,.btn-magento-gris-claro:not(:disabled):not(.disabled).active:focus,.show>.btn-magento-gris-claro.dropdown-toggle:focus{box-shadow:0 0 0 1px rgba(173,173,173,0.7)}
.btn-magento-gris-claro.filtro-activado {border-bottom: transparent; border-top: 2px solid RGB(0,32,96);border-left: 1px solid #726f6d; border-right: 1px solid #726f6d; background-color:white; margin-top: -3px}
.btn-magento-gris-claro.filtro-activado:hover {color: #fff !important; background-color: #0062cc !important; border-color: #0062cc !important;}
/*FBotones*/

.btn-group > .btn, .btn-group-vertical > .btn { flex: 0 1 auto; }


/*Comportamientos de colores de tabla alternos*/
.mat-row:nth-child(even){
    background-color:#f5f5f5;
    }

.mat-row:nth-child(odd){
    background-color:#fff;
    }

tr.mat-row:hover {
      background-color:#e4e4e4;
      cursor: pointer;
    }


.mat-cell {
    border-left: .1rem dashed #d6d6d6;
    border-right: .1rem dashed #d6d6d6;
    padding-left: .5rem !important;}

td.mat-cell:first-child {border-left: .1rem solid #d6d6d6;}
td.mat-cell:last-child {border-right: .1rem solid #d6d6d6;}

.mat-footer-cell:first-child, .mat-header-cell:first-child {
    padding-left: .5rem !important;
  }


/*Filtro = magento*/
.customan-filtro-input
{
    width: 30% !important;
    /*padding: 1rem 0px 0px 1rem;*/
}
.customan-filtro-input .mat-form-field-wrapper .mat-form-field-flex
{
    border: 1px solid #adadad !important;
}

.customan-filtro-input .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix input
{
    width: 90%;
}
.customan-filtro-input .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix i
{
    float: right;
    padding-right: .5rem;
}

.magento-paginator
{
    width: 50%;
    float: right;
}

.magento-acciones
{
    width: 25% !important;
    float: left;
    padding-left: 1rem;
    min-width: 25rem;
}

.customan-botonera-magento-tabla
{
    /*width: 50%;
    position: absolute;
    right: 15px;*/
    text-align: right;
    padding: 2rem 0px 0px 1rem;
    float: none;
}

        /*Paneles*/

        .customan-panel-filtros-tabla
        {
          opacity: 1;
          visibility: visible;
          border-bottom: 0.1rem solid #adadad;
          border-top: 0.1rem solid #adadad;
          margin-bottom: .35rem !important;
          padding: 15px 10px 30px 10px !important;
          position: relative;
          top: -0.1rem;
          display: block;
        }

        .customan-panel-columnas-tabla
        {
            border: 1px solid #adadad;
            box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
            background: white;
            opacity: .90;
            position: absolute;
            right: 15px;
            /*margin-top: -0.35rem;*/
            margin-top: -0.05rem;
            padding: 1rem;
            width: 33%;
            z-index: 290;
        }

        .container {
          width: 100%;
          padding: 0px 30px 0px 0px;
          margin-left: 15px !important;

        }
        .containerColumnas-tabla
        {
            height: 200px;
            overflow: auto;
            border: 1px solid #adadad;
        }

        .containerFiltros-tabla {
            float: none;
        }

        .columnas-listado-elemento
        {
            width: 33% !important;
            float: left;
            font-size: 0.8rem !important;
        }

        .columnas-listado-elemento .mat-list-item-content
        {
            padding: 0 0.5rem !important;
        }

        .columnas-listado-elemento .mat-list-item-content .mat-list-text
        {
            padding-left: 0.5rem !important;
        }

        .grupo-filtros-campo-registro
        {
            width: 24%;
            float: left;
            padding-right: 1rem;
        }

        .customan-panel-filtros-activos
        {
            display:block;
            /* border-bottom: 1px solid #adadad;
            border-top:  1px solid #adadad; */
            margin-bottom: 15px;
            background-color: #f8f8f8;
            padding: 5px;
            flex: 2;
        }
        .customan-panel-filtros-activos ul, .customan-panel-filtros-activos li {
            display:inline;
            padding-inline-start: 0px;
        }

        .customan-panel-filtros-activos li
         {
            padding-inline-end: 1rem;
            font-size: 0.9rem;
         }

         .customan-panel-filtros-activos li i.fa
         {
             color: #adadad;
         }
/*Ftabla*/

/*MaterialCheckbox*/
.customan-magento-check .mat-checkbox-background {
    background-color:white !important;
    border: 1px solid #303030 !important;
}

.mat-checkbox-checked.customan-magento-check .mat-checkbox-background,
.mat-checkbox-indeterminate.customan-magento-check .mat-checkbox-background {
    background-color:white !important;
    border: 1px solid #303030 !important;
  }

.customan-magento-check .mat-ripple-element {
    background: #303030 !important;
  }

.customan-magento-check .mat-checkbox-checkmark-path {
    stroke: #303030 !important;
}
.mat-checkbox-mixedmark {
    background: #303030 !important;
}

/*CheckboxList*/

.columnas-listado-elemento .mat-list-item-content .mat-pseudo-checkbox
{
    background-color:white !important;
    border: 1px solid #303030 !important;
}

.columnas-listado-elemento .mat-list-item-content mat-pseudo-checkbox.mat-pseudo-checkbox.mat-pseudo-checkbox-checked::after
{
    color: #303030;
    top: 0.25rem;
    left: 0.20rem;
}
/*FMCheckbox*/


/*Botonera de Acciones*/
/*Separacion*/
.menu-acciones-metodo button
{
margin-left: .5rem;
}

/*Material desplegable*/
.calendarioInput
{
    padding: 1rem;
    display: inline-block;
}

.calendarioInput .mat-form-field-type-mat-input
{
    width: 49% !important;
    padding: 1rem;
}

.mat-expansion-panel-header-title label
{
    color: #000;
    padding: 2px;
    text-align: right;
    background-clip: padding-box;
    position: relative;
    vertical-align: middle;
    width: 13%;
    margin: 1%;
    font-weight: 500;
}

.mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title label
{
    color: rgb(136, 133, 133);
    font-weight: 400;
}

.input-idiomas-editor
{
    width:85%;
    display: inline-block;
    padding: 1rem;
}

.loading-shade {
    position: absolute;
    top: 270px;
    left: 0;
    bottom: 56px;
    right: 0;
    /* background: rgba(0, 0, 0, 0.15); */
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.alinear-contenido-centrado {text-align: center;}

.w33 {width: 33% !important; padding: 3%;}
.w33.izquierda, .w33.derecha {
    /* display: inline-table; */
    overflow: auto;
}
.w42 {width: 43% !important; padding: 2%;}
.w42.izquierda, .w42.derecha {
    /* display: inline-table; */
    overflow: auto;
}
.w15 {width: 14% !important; padding: 3% 0.5%;}
.input-lista-editor {
    /* min-height: 10rem;
    max-height: 30rem;
    height: 10%; */
    display: flex;
    /* flex-direction: column; */
}
.input-lista-editor mat-icon
{
    font-size: inherit !important;
}

.input-lista-editor mat-list-item
{
    height: 1.5rem !important;
}

.mat-slide-toggle-thumb-container
{
    z-index: 0 !important;
}

.grupo-editor-campo-registro .mat-radio-button
{
    padding-right: 1rem;
}

.texto-color-rojo
{
    color: red;
}

.grupo-editor-campo-registro .invalid-feedback
{
    width: 85%;
    /*margin-left: 15%;*/
}

.grupo-editor-campo-registro .mat-form-field:not(.w33)
{
    width: 85% !important;
}

.is-invalid { border: 1px solid red;}

.overflow-table{
  overflow-x: scroll;
  margin-bottom: 50px;
}

 /* Media Querys Pantallas*/
@media (min-width: 576px){
  .container.resize  {
      max-width: 540px;
    }
  }

@media (min-width: 992px){
.container.resize  {
    max-width: 740px;
  }
}

@media (min-width: 1120px){
  .container.resize  {
      max-width: 830px;
    }
  }

@media (min-width: 1210px){
  .container.resize  {
      max-width: 960px;
    }
  }

@media (min-width: 1280px){
  .container.resize  {
      max-width: 1035px;
    }
  }

  @media (min-width: 1350px){
    .container.resize  {
      max-width: 1100px;
      }
    }

    @media (min-width: 1550px){
      .container.resize  {
        max-width: none;
        }
      }
.mat-select-panel{
 max-height: 300px !important;
}

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: rgb(128, 128, 128);
}

app-usuarios.col.ng-star-inserted {
  padding-left: 0;
}

/* .mat-select-arrow {
  border: none !important;
  background-image: url(/assets/icon/desplegable.svg);
  background-repeat: no-repeat;
  background-position: right calc(0.175em) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  padding: 8px;
} */

.mat-list-text span:hover {
  color:#fff;
  background-color: #002c76;
}

.mat-list-text span:hover mat-icon {
  filter: saturate(11) brightness(3.5);
}

/* .cdk-overlay-pane {
  transform: translateX(-15px) translateY(22px) !important;
} */

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {

  position: relative;
  width: 100%;
  padding-right: 15px !important;
  padding-left: 15px !important;

}

td button mat-icon {
  color: #333;
}

.menu-acciones-metodo {
  border-top: 0px !important;
  padding-top: 0px !important;
  border-bottom: 1px solid #e3e3e3 !important;
}

.data-grid {
  padding-top: 0px !important;
}

.customan-botonera-magento-tabla {
  padding-top: 0px !important;
}

.blue-snackbar {
  background: $snackbar-information;
  color: $white-text;
}
.green-snackbar {
  background: $snackbar-success;
  color: $white-text;
}

.red-snackbar{
  background: $snackbar-alert;
  color: $white-text;
}

.mat-simple-snackbar-action {
  color: #002c76 !important;
}

.menu-acciones-metodo .btn-magento-atras {
  color: #002c76 !important;
}

.menu-acciones-metodo .btn-magento-atras i {
  color: #002c76 !important;
}

/* width */
 ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(93, 133, 160);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(93, 133, 160);;
  border-radius: 10px;
} 

.row {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.panelalpha {
  opacity: 0.3;
}
