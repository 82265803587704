/* @import '~@angular/material/theming';
@include mat-core();



$my-theme-primary: mat-palette($mat-blue-grey);
$my-theme-accent: mat-palette($mat-pink, A200, A100, A400);
$my-theme-warn: mat-palette($mat-red);

$my-theme: mat-light-theme($my-theme-primary, $my-theme-accent, $my-theme-warn);

@include angular-material-theme($my-theme); 
  */

  @import '~@angular/material/theming';
  @include mat-core();
  
  $customan-primary: mat-palette($mat-blue-gray);
  
  $customan-accent: mat-palette($mat-pink);
  
  $customan-warn: mat-palette($mat-red);
  
  $customan-theme: mat-light-theme($customan-primary, $customan-accent, $customan-warn);
  
  
  @include angular-material-theme($customan-theme);
  $white-text: mat-color($mat-grey,50);
  $snackbar-alert: mat-color($mat-red,500);
  $snackbar-success: mat-color($mat-green,400);
  $snackbar-information: mat-color($mat-blue,400);
  
   $maincolor: #002c76; 
/*   $maincolor: mat-color($mat-indigo,900);
 */   
   $mainaccent: #011a46; 
/*   $mainaccent: mat-color($mat-indigo,800);
 */  
  $mainsoft: mat-color($mat-blue-gray,400);
  
  $maintext: mat-color($mat-blue-gray,100);
  $accenttext: mat-color($mat-blue-gray,50);

  #logomenu {
    background-image: url(  '/assets/images/pedrera/logo-menu.png');
    background-repeat: no-repeat;
    width: 200px;
    height: 100%;
    margin: 20px 0px 0px 35px;
  }
  
  #logoportada {
    background-image: url(  '/assets/images/pedrera/logo-portada.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 260px;
    width: 100%;
    display: inline-block;
  }
  